@font-face {
  font-family: "reg";
  src: url("./fonts/muse-regular.ttf") format("truetype");
}
@font-face {
  font-family: "ita";
  src: url("./fonts/muse-italic.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  font-family: "reg";
}

#container {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
#blocker {
  position: absolute;
  width: 100%;
  background-color: rgba(255, 231, 211, 0.7);
}

#instructions {
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

section {
  position: relative;
  width: 100vw;
  height: 100vh;
}
#x {
  height: 3vh;
  background: #ffe7d3;
}
/**** Section 1 ****/
/**** Section 1 ****/
/**** Section 1 ****/

#top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #ffe7d3;
}

#htop1 {
  font-size: 6rem;
  position: absolute;
  top: 0;
  left: 0;
}
.landing-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-clip: rgba(255, 231, 211, 0.5);

  fill: none;
  width: 30vw;
  height: 20vh;
}
#ptop {
  font-size: 2rem;
  width: 100%;
  font-family: "ita";
}
#loaderw {
  font-size: 1.5rem;
  width: 100%;
  font-family: "reg";
}
#htop2 {
  font-size: 6rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2vw;
}
#zax {
  content: "";
}
/**** Section 2 ****/
/**** Section 2 ****/
/**** Section 2 ****/

#middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffe7d3;
  height: 120vh;
}
#hmiddle {
  font-size: 5rem;
  width: 40%;
}
.pmiddle {
  font-size: 1.5rem;
  width: 40%;
}
.text-path {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100vw;
  height: 50%;

  opacity: 0.245;
}
textPath {
  position: absolute;
  font-size: 2rem;
  text-transform: uppercase;
  fill: #8b6039;
}

/**** Section 3 ****/
/**** Section 3 ****/
/**** Section 3 ****/
#bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
}
#hbottom {
  font-size: 5rem;
  width: 40%;
}
.pbottom {
  font-size: 1.5rem;
  width: 40%;
}

/**** footer ****/
/**** footer ****/
/**** footer ****/

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}
footer p {
  font-size: 1.2rem;
  width: 40%;
  z-index: 3;
}

/**** button ****/
/**** button ****/
/**** button ****/
button {
  padding: 20px;
  font-family: "reg";
  background-color: #363636;
  color: #ffffff;

  font-weight: 500;
  width: 100%;
  max-width: 450px;
  font-size: 22.125px;
  margin-top: 25px;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 1;
}

/**** Responsive ****/
/**** Responsive ****/
/**** Responsive ****/

@media screen and (max-width: 750px) {
  #htop1 {
    font-size: 5rem;
  }
  #htop2 {
    font-size: 5rem;
  }
  .landing-content-container {
    width: 70vw;
    height: 20vh;
  }
  #hmiddle {
    font-size: 4rem;
    width: 70%;
  }
  .pmiddle {
    font-size: 1.3rem;
    width: 70%;
  }
  #hbottom {
    font-size: 4rem;
    width: 70%;
  }
  .pbottom {
    font-size: 1.3rem;
    width: 70%;
  }
  button {
    font-size: 18px;
    max-width: 350px;
  }
  footer p {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  #htop1 {
    font-size: 4rem;
  }
  #htop2 {
    font-size: 4rem;
  }
  .landing-content-container {
    width: 90vw;
    height: 20vh;
  }
  #hmiddle {
    font-size: 3rem;
    width: 90%;
  }
  .pmiddle {
    font-size: 1.2rem;
    width: 90%;
  }
  #hbottom {
    font-size: 3rem;
    width: 90%;
  }
  .pbottom {
    font-size: 1.2rem;
    width: 90%;
  }

  button {
    font-size: 15px;
    max-width: 250px;
  }
  footer p {
    width: 90%;
  }
}

#scroll-wrapper {
  display: none;
  width: 100%;
  height: 100%;
}

#scroll-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#scroll-down {
  display: block;
  position: relative;
  padding-top: 79px;
  text-align: center;
}

#scroll-title {
  display: block;
  text-transform: uppercase;
  color: black;
  font-family: "reg";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4em;
  text-align: center;
  transform: rotate(90deg);
  margin-bottom: 45px;
}
#scroll-down::before {
  -webkit-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  /* Safari 4+ */

  -moz-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  /* Fx 5+ */

  -o-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  /* Opera 12+ */

  animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  /* IE 10+, Fx 29+ */

  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 90px;
  background: black;
  content: " ";
}
@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
